var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"honor"},[_c('el-header',{staticClass:"honor-top"},[_c('Header')],1),_c('el-main',{staticClass:"honor-center"},[_c('Wall',{attrs:{"title":"企业新闻","english":"Company News"}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.row.title))]),_c('div',{staticClass:"date"},[_vm._v("发布时间："+_vm._s(_vm.row.year)+"-"+_vm._s(_vm.row.month))])])]),_c('hr',{staticClass:"hr"}),_c('div',{staticClass:"list"},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.detail)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.detail1)+" ")]),_c('img',{attrs:{"src":_vm.row.img,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text5)+" ")]),_c('img',{attrs:{"src":_vm.row.img1,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline1)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text6)+" ")]),_c('img',{attrs:{"src":_vm.row.img2,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline2)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text7)+" ")]),_c('img',{attrs:{"src":_vm.row.img3,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline3)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text8)+" ")]),_c('img',{attrs:{"src":_vm.row.img5,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline5)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text9)+" ")]),_c('img',{attrs:{"src":_vm.row.img11,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline11)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text10)+" ")]),_c('img',{attrs:{"src":_vm.row.img7,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline7)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text11)+" ")]),_c('img',{attrs:{"src":_vm.row.img8,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline8)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text12)+" ")]),_c('img',{attrs:{"src":_vm.row.img9,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline9)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text13)+" ")]),_c('img',{attrs:{"src":_vm.row.img10,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline10)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text14)+" ")]),_c('img',{attrs:{"src":_vm.row.img6,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.row.headline6)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text1)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text2)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text3)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text4)+" ")]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text15)+" ")]),_c('img',{attrs:{"src":_vm.row.img4,"alt":""}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 6,
        minFontSize: '1px',
        maxFontSize: '500px',
        delay: 200,
      }),expression:"{\n        ratio: 6,\n        minFontSize: '1px',\n        maxFontSize: '500px',\n        delay: 200,\n      }"}],staticClass:"text"},[_vm._v(" "+_vm._s(_vm.row.text16)+" ")])])],1),_c('div',{staticClass:"honor-footer"},[_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }