<template>
  <el-container class="honor">
    <el-header class="honor-top">
      <Header />
    </el-header>
    <el-main class="honor-center">
      <Wall title="企业新闻" english="Company News" />
      <div class="info">
        
        <div class="head">
          <div class="title">{{ row.title }}</div>
          <div class="date">发布时间：{{ row.year }}-{{ row.month }}</div>
        </div>
      </div>
      <hr class="hr" />
      <div class="list">
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.detail }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.detail1 }}
        </div>

        <img :src="row.img" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text5 }}
        </div>

        <img :src="row.img1" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline1 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text6 }}
        </div>

        <img :src="row.img2" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline2 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text7 }}
        </div>

        <img :src="row.img3" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline3 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text8 }}
        </div>

        <img :src="row.img5" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline5 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text9 }}
        </div>

        <img :src="row.img11" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline11 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text10 }}
        </div>

        <img :src="row.img7" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline7 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text11 }}
        </div>

        <img :src="row.img8" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline8 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text12 }}
        </div>

        <img :src="row.img9" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline9 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text13 }}
        </div>

        <img :src="row.img10" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline10 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text14 }}
        </div>

        <img :src="row.img6" alt="" />
        <div class="headline"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.headline6 }}
        </div>

        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text1 }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text2 }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text3 }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text4 }}
        </div>
        <div class="text"
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }"
        >
          {{ row.text15 }}
        </div>

        <img :src="row.img4" alt="" />

        <div class="text"
        
        v-resize-text="{
          ratio: 6,
          minFontSize: '1px',
          maxFontSize: '500px',
          delay: 200,
        }">
          {{ row.text16 }}
        </div>
      </div>
    </el-main>
    <div class="honor-footer">
      <Footer />
    </div>
  </el-container>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Wall from "@/views/components/Wall.vue";
export default {
  components: {
    Wall,
    Header,
    Footer,
  },
  data() {
    return {
      list: [
        {
          text: "“中朝是山水相连的友好邻邦，两国保持着传统友好合作关系。同时，朝鲜是联合国正式成员国，是主权独立国家。中朝关系好同中方对朝影响力是两个不同的概念，不能混为一谈。”毛宁说。",
          img: require("../assets/imgs/news1.png"),
          title: "中方将继续在朝鲜半岛问题上积极维稳促谈",
          date: "2023.09.05",
          id: "1",
        },
        {
          text: "据悉，该交流会由中央广播电视总台经济之声、央广网共同推出，以主题调研采访形式，探索高质量发展建设共同富裕示范区的“江夏经验”。",
          img: require("../assets/imgs/news1.png"),
          title: "共同富裕中国行”主题采访活动走进武汉江夏",
          date: "2023.09.05",
          id: "2",
        },
        // {
        //     text:"“中朝是山水相连的友好邻邦，两国保持着传统友好合作关系。同时，朝鲜是联合国正式成员国，是主权独立国家。中朝关系好同中方对朝影响力是两个不同的概念，不能混为一谈。”毛宁说。",
        //     img:require("../assets/imgs/new3.png"),
        //     title:'中方将继续在朝鲜半岛问题上积极维稳促谈',
        //     date:'2023.09.05'
        // },
        // {
        //     text:"“中朝是山水相连的友好邻邦，两国保持着传统友好合作关系。同时，朝鲜是联合国正式成员国，是主权独立国家。中朝关系好同中方对朝影响力是两个不同的概念，不能混为一谈。”毛宁说。",
        //     img:require("../assets/imgs/new3.png"),
        //     title:'中方将继续在朝鲜半岛问题上积极维稳促谈',
        //     date:'2023.09.05'
        // },
      ],
      row: {},
    };
  },
  mounted() {
    this.row = JSON.parse(sessionStorage.getItem("title"));
  },
};
</script>

<style scoped lang="less">
.honor-center {
  padding: 0 !important;
  display: block !important;
}
.honor {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &-top {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
.info {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
  .title {
    font-size: 26px;
    color: black;
    font-weight: 500;
  }
  .date {
    font-size: 22px;
    margin-top: 6px;
    color: #333;
  }
}
.list {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
  .text {
    text-indent: 2em;
    letter-spacing: 2px;
    margin-top: 20px;
    line-height: 40px;
    color: #333;
    font-size: 22px
  }
  .headline {
    text-align: center;
    color: #333;
    font-size: 22px
  }
  img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
  }
}
</style>
